import { Component } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { WizardStepBaseComponent } from '../';
import { TWizardStepId } from '../../../interfaces';
import { AppSettings } from '../../../models/app-settings.model';
import { AppSettingsService } from '../../../services/app-settings.service';

@Component({
    selector: 'app-wizard-step-logged-in',
    templateUrl: './wizard-step-logged-in.component.html',
    styleUrls: ['../base/wizard-step-base.component.scss', './wizard-step-logged-in.component.scss'],
    providers: [{ provide: WizardStepBaseComponent, useExisting: WizardStepLoggedInComponent }],
})
export class WizardStepLoggedInComponent extends WizardStepBaseComponent {
    public id: TWizardStepId = 'logged-in';
    public nextButton = 'toPortal';

    public url: AppSettings['url'];

    public constructor(appSettings: AppSettingsService) {
        super();

        appSettings
            .get(
                tap((settings) => {
                    this.url = settings.url;
                    this.nextPageLink = this.url.portal;
                })
            )
            .subscribe();
    }

    protected submitWizardStep(): Observable<boolean> {
        return of(false);
    }
}
