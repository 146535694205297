import { Component, ElementRef, EventEmitter, ViewChild, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { IWizardForm, TWizardStepId } from '../../../interfaces';
import { WizardService } from '../../../services/wizard.service';

@Component({ template: '' })
export abstract class WizardStepBaseComponent {
    public abstract id: TWizardStepId;
    public abstract nextButton: string;

    protected get formControl(): UntypedFormControl | undefined {
        return (this.stepForm as any)?.form as UntypedFormControl;
    }

    public visible: boolean;
    public nextPageLink: string | null = null;

    protected get form(): IWizardForm {
        return this.wizardService.form;
    }

    protected get goToPage(): EventEmitter<TWizardStepId | number> {
        return this.wizardService.goToPage;
    }

    @ViewChild('stepForm')
    protected stepForm?: ElementRef<UntypedFormControl>;

    protected readonly wizardService: WizardService = inject(WizardService);

    public validateAndSubmitForm(): Observable<boolean> {
        this.onBeforeValidate?.();

        return this.formControl
            ? this.validateFormPage().pipe(switchMap((isValid) => (isValid ? this.submitWizardStep() : of(false))))
            : this.submitWizardStep();
    }

    public show(): void {
        this.visible = true;
        this.onShow?.();
    }

    public hide(): void {
        this.visible = false;
        this.onHide?.();
    }

    // Optional hooks that can be implemented by subclasses
    public onBeforeValidate?(): void;

    public onBeforeShow?(): Observable<unknown> | void;

    public onBeforeHide?(): Observable<unknown> | void;

    protected onShow?(): void;

    protected onHide?(): void;

    protected abstract submitWizardStep(): Observable<boolean>;

    private validateFormPage(): Observable<boolean> {
        const formControl = this.formControl;

        formControl?.updateValueAndValidity();
        formControl?.markAllAsTouched();

        return of(!formControl?.invalid);
    }
}
