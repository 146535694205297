import { AfterViewInit, Component, EventEmitter, QueryList, ViewChildren } from '@angular/core';
import { debounce, firstValueFrom, of, switchMap, timer } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { fade } from 'shared';

import { IWizardForm, IWizardStep, TWizardStepId } from '../../interfaces';
import { AppSettingsService } from '../../services/app-settings.service';
import { WizardService } from '../../services/wizard.service';
import { WizardStepBaseComponent } from '../wizard-step';

@Component({
    selector: 'app-user-portal-wizard',
    templateUrl: './wizard.component.html',
    styleUrls: ['./wizard.component.scss'],
    animations: [fade()],
})
export class WizardComponent implements AfterViewInit {
    public backLinkUrl: string;
    public nextDisabled = false;

    protected goToPage: EventEmitter<TWizardStepId | number> = new EventEmitter();

    @ViewChildren(WizardStepBaseComponent)
    protected steps?: QueryList<WizardStepBaseComponent>;

    public wizardSteps: Array<IWizardStep> = [
        { id: 'login', image: 'page_1.svg', nextStepId: 'logged-in' },
        { id: 'account', image: 'page_1.svg', nextStepId: 'logged-in' },
        { id: 'credentials', image: 'page_1.svg', nextStepId: 'update-success' },
        { id: 'profile', image: 'page_1.svg', nextStepId: 'update-success' },
        { id: 'tenant-invite', image: 'page_2.svg', nextStepId: 'tenant-invite-success' },
        { id: 'tenant-invite-success', image: 'page_2.svg', nextStepId: 'logged-in' },
        { id: 'update-success', image: 'page_4.png', nextStepId: 'logged-in' },
        { id: 'logged-in', image: 'page_4.png' },
    ];

    public formData: IWizardForm = {} as IWizardForm;
    public currentStep = 0;

    public get step(): IWizardStep {
        return this.wizardSteps[this.currentStep];
    }

    public constructor(appSettings: AppSettingsService, wizardService: WizardService) {
        appSettings.get(tap((settings) => (this.backLinkUrl = settings.url.backLink))).subscribe();

        this.goToPage
            .pipe(
                debounce(() => timer(100)),
                tap((pageId) => {
                    this._goToPage(pageId);
                })
            )
            .subscribe();

        wizardService.initialize(this.formData, this.goToPage, this.nextStep.bind(this));
    }

    public ngAfterViewInit(): void {
        this.stepComponent.show();
    }

    public nextStep(): void {
        this.nextDisabled = true;

        (this.stepComponent.validateAndSubmitForm() ?? of(true))
            .pipe(
                take(1),
                switchMap(async (isValid) => {
                    if (isValid) {
                        if (this.step.nextStepId) return this._goToPage(this.step.nextStepId);
                        if (this.currentStep < this.wizardSteps.length - 1)
                            return this.goToPageByIndex(this.currentStep + 1 + (this.step.skipNextSteps || 0));
                    }
                    this.nextDisabled = false;
                })
            )
            .subscribe();
    }

    protected get stepComponent(): WizardStepBaseComponent {
        return this.steps?.find((s) => s.id === this.step.id);
    }

    private async _goToPage(id: TWizardStepId | number): Promise<void> {
        return this.goToPageByIndex(typeof id === 'number' ? id : this.wizardSteps.findIndex((s) => s.id === id));
    }

    private async goToPageByIndex(index: number): Promise<void> {
        let component = this.stepComponent;
        const onBeforeHideResult = component.onBeforeHide?.();
        if (onBeforeHideResult) await firstValueFrom(onBeforeHideResult);
        component.hide();

        this.currentStep = index;

        component = this.stepComponent;
        const onBeforeShowResult = component.onBeforeShow?.();
        if (onBeforeShowResult) await firstValueFrom(onBeforeShowResult);
        component.show();
        this.nextDisabled = false;
    }
}
