<form #stepForm="ngForm" class="form-page" *ngIf="visible">
    <label>
        <span>{{ 'form.loginUsername' | translate }}</span>
        <input
            #username="ngModel"
            type="text"
            name="username"
            required
            [placeholder]="'form.loginUsername' | translate"
            [(ngModel)]="form.username"
        />
    </label>
    <label>
        <span>{{ 'form.oldPassword' | translate }}</span>
        <input
            #password="ngModel"
            type="password"
            name="password"
            [required]="stepForm.dirty"
            (keyup)="validatePasswords()"
            [placeholder]="'form.oldPassword' | translate"
            [(ngModel)]="form.password"
        />
    </label>
    <label>
        <span>{{ 'form.newPassword' | translate }}</span>
        <input
            #newPassword="ngModel"
            type="password"
            name="newPassword"
            minlength="8"
            [required]="!!form.passwordConfirm"
            (change)="validatePasswords()"
            [placeholder]="'form.newPassword' | translate"
            [(ngModel)]="form.newPassword"
            [ngModelOptions]="{ updateOn: 'blur' }"
        />
    </label>
    <label>
        <span>{{ 'form.newPasswordConfirm' | translate }}</span>
        <input
            #passwordConfirm="ngModel"
            type="password"
            name="passwordConfirm"
            [required]="!!form.newPassword"
            (change)="validatePasswords()"
            [placeholder]="'form.newPasswordConfirm' | translate"
            [(ngModel)]="form.passwordConfirm"
        />
    </label>
    <div class="form-error" *ngIf="stepForm.invalid">
        <div *ngIf="username.errors?.usernameTaken">{{ 'validation.usernameTaken' | translate }}</div>
        <div *ngIf="password.errors?.invalid">{{ 'errors.invalidCredentials' | translate }}</div>
        <div *ngIf="newPassword.errors?.sameAsOld">{{ 'validation.credentials.sameAsOld' | translate }}</div>
        <div *ngIf="newPassword.errors?.invalid || newPassword.errors?.minlength">
            {{ 'validation.credentials.invalid' | translate }}
        </div>
        <div *ngIf="passwordConfirm.errors?.mismatch">{{ 'validation.credentials.mismatch' | translate }}</div>
    </div>
</form>
