<form #stepForm="ngForm" class="form-page" *ngIf="visible">
    <input type="text" name="tenantName" required hidden #tenantName="ngModel" [(ngModel)]="form.tenantName" />
    <label [class.hidden]="state === stateEnum.SUCCESS">
        <span>{{ 'form.token' | translate }}</span>
        <input
            id="token-input"
            type="text"
            name="token"
            required
            #token="ngModel"
            [placeholder]="'form.token' | translate"
            [(ngModel)]="form.token"
            [disabled]="state >= stateEnum.LOADED"
            [minlength]="tokenValidation.length"
            [maxlength]="tokenValidation.length"
            [pattern]="tokenValidation.pattern"
            (paste)="onTokenPaste($event)"
            (keyup)="onTokenInput()"
        />
    </label>

    <div class="title-start" *ngIf="state !== stateEnum.START && loadedInvitation.tenantName">
        <strong>{{ 'steps.tenant-invite.start' | translate : loadedInvitation }}</strong>
        <p [innerHTML]="'steps.tenant-invite.start_subtitle' | translate : loadedInvitation"></p>
    </div>

    <div class="form-error" *ngIf="state === stateEnum.ERROR">
        <ng-container *ngFor="let errorCode of inviteErrorCodes">
            <div *ngIf="tenantName.errors?.[errorCode]">
                {{
                    'validation.inviteErrorReason'
                        | translate : { reason: 'validation.reasons.' + errorCode | translate }
                }}
            </div>
        </ng-container>
        <div *ngIf="tenantName.errors?.inviteError">{{ 'validation.invite.inviteError' | translate }}</div>
        <div *ngIf="token.errors?.invalidToken || invalidToken">{{ 'validation.invalidToken' | translate }}</div>
        <div *ngIf="token.errors?.expiredToken">{{ 'validation.expiredToken' | translate }}</div>
        <div *ngIf="maxLengthExceeded || token.errors?.maxLength">
            {{ 'validation.exceededMaxLengthToken' | translate }}
        </div>
    </div>
</form>
