<div class="logged-in-wrapper" *ngIf="visible">
    <div class="title-start">
        <strong>{{ 'steps.logged-in.start' | translate }}</strong>
        <p>{{ 'steps.logged-in.start_subtitle' | translate }}</p>
    </div>
    <div class="buttons">
        <button type="button" class="btn color-primary btn-signup" (click)="goToPage.emit('tenant-invite')">
            <span>{{ 'buttons.goToInvite' | translate }}</span>
        </button>
        <button type="button" class="btn color-primary btn-signup" (click)="goToPage.emit('credentials')">
            <span>{{ 'buttons.goToCredentials' | translate }}</span>
        </button>
        <button type="button" class="btn color-primary btn-signup" (click)="goToPage.emit('profile')">
            <span>{{ 'buttons.goToProfile' | translate }}</span>
        </button>
    </div>
    <div class="title-download">
        <strong>{{ 'steps.logged-in.download' | translate }}</strong>
        <p>{{ 'steps.logged-in.download_subtitle' | translate }}</p>
    </div>
    <div class="store_logos">
        <a [href]="url.appStore" target="_blank">
            <img src="assets/img/appstore.png" [alt]="'general.appStore' | translate" />
        </a>
        <a [href]="url.playStore" target="_blank">
            <img src="assets/img/googleplay.png" [alt]="'general.googlePlay' | translate" />
        </a>
    </div>
</div>
