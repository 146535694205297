import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, AuthUser } from 'baseflow-auth';
import { Observable, from, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { WizardStepBaseComponent } from '../';
import { TWizardStepId } from '../../../interfaces';
import { AppSettingsService } from '../../../services/app-settings.service';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-wizard-step-profile',
    templateUrl: './wizard-step-profile.component.html',
    styleUrls: ['../base/wizard-step-base.component.scss', 'wizard-step-profile.component.scss'],
    providers: [{ provide: WizardStepBaseComponent, useExisting: WizardStepProfileComponent }],
})
export class WizardStepProfileComponent extends WizardStepBaseComponent {
    public id: TWizardStepId = 'profile';
    public nextButton = 'back';

    public termsUrl: string;

    protected languages: Array<string> = ['en', 'nl'];
    private initialLanguage: string;

    public constructor(
        private readonly appSettings: AppSettingsService,
        private readonly translate: TranslateService,
        private readonly auth: AuthService,
        private readonly userService: UserService
    ) {
        super();
    }

    @HostListener('keyup.enter')
    public onSubmit(): void {
        this.wizardService.stepSubmit.emit();
    }

    public override onBeforeShow(): Observable<AuthUser> {
        return this.auth.user.pipe(
            tap((user) => {
                this.form.userId = user.id;
            })
        );
    }

    protected setLanguage(language: string): void {
        const formControl = this.formControl.get('language');
        formControl.setValue(language);
        this.initialLanguage === language ? formControl.markAsPristine() : formControl.markAsDirty();

        this.updateNextButton();
    }

    protected updateNextButton(): void {
        this.nextButton = this.formControl.dirty ? 'updateProfile' : 'back';
    }

    protected submitWizardStep(): Observable<boolean> {
        if (!this.formControl.dirty) {
            this.goToPage.emit('logged-in');
            return of(false);
        }
        const { userId, language, emailAddress } = this.form;

        return from(this.userService.updateProfile(userId, language, emailAddress)).pipe(
            catchError((response) => {
                if (response?.error?.errorDescription === 'Invalid email address specified.')
                    this.formControl.get('emailAddress').setErrors({ email: true });

                return of(false);
            }),
            tap(() => this.translate.use(language)),
            map((response) => response !== false)
        );
    }
}
