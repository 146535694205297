<div class="page-container">
    <div class="wizard" *ngIf="wizardSteps">
        <div class="logo">
            <img src="assets/img/logo.png" [alt]="'general.brandLogo' | translate" />
        </div>
        <div class="backlink">
            <span class="material-icons">arrow_back</span>
            <a [href]="backLinkUrl">onego.io</a>
        </div>
        <div class="dialog">
            <div class="page-image">
                <img [src]="'assets/img/' + step.image" [alt]="'steps.titles.' + step.id | translate" />
                <span class="page-image-tenant" *ngIf="step.id === 'tenant-invite'">{{ formData.tenantName }}</span>
            </div>
            <div class="steps">
                <h1 class="steps-title">
                    {{ 'steps.titles.' + step.id | translate }}
                </h1>
            </div>
            <div class="form">
                <app-wizard-step-login />
                <app-wizard-step-create-account />
                <app-wizard-step-credentials />
                <app-wizard-step-profile />
                <app-wizard-step-tenant-invite />
                <app-wizard-step-tenant-invite-success />
                <app-wizard-step-logged-in />
                <app-wizard-step-update-success />
            </div>
            <div class="controls">
                <button
                    *ngIf="!stepComponent?.nextPageLink"
                    type="button"
                    class="btn color-secondary"
                    (click)="nextStep()"
                    [disabled]="nextDisabled"
                >
                    <span *ngIf="!nextDisabled" @fade>{{ 'buttons.' + stepComponent?.nextButton | translate }}</span>
                    <ogs-spinner *ngIf="nextDisabled" @fade></ogs-spinner>
                </button>
                <a
                    *ngIf="stepComponent?.nextPageLink"
                    [href]="stepComponent?.nextPageLink"
                    target="_blank"
                    class="btn color-secondary"
                >
                    {{ 'buttons.' + stepComponent?.nextButton | translate }}
                </a>
            </div>
        </div>
    </div>
</div>
