<form #stepForm="ngForm" class="form-page" *ngIf="visible">
    <label>
        <span>{{ 'form.emailAddress' | translate }}</span>
        <input
            #emailAddress="ngModel"
            type="text"
            name="emailAddress"
            required
            [placeholder]="'form.emailAddress' | translate"
            [(ngModel)]="form.emailAddress"
        />
        <div class="form-error" *ngIf="!emailAddress.valid">
            <div *ngIf="emailAddress.errors?.usernameTaken">
                {{ 'validation.usernameTaken' | translate }}
            </div>
        </div>
    </label>
    <label>
        <span>{{ 'form.password' | translate }}</span>
        <input
            type="password"
            name="password"
            required
            [placeholder]="'form.password' | translate"
            [(ngModel)]="form.password"
        />
    </label>
    <div class="login-error" @fade *ngIf="loginError">{{ loginError | translate }}</div>
    <button type="button" class="btn color-primary btn-signup" (click)="goToPage.emit('account')">
        <span>{{ 'buttons.goToSignUp' | translate }}</span>
    </button>
</form>
