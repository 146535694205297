import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, AuthUser, CredentialsService, ICredentials } from 'baseflow-auth';
import { Observable, from, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { WizardStepBaseComponent } from '../';
import { TWizardStepId } from '../../../interfaces';
import { AppSettingsService } from '../../../services/app-settings.service';

@Component({
    selector: 'app-wizard-step-credentials',
    templateUrl: './wizard-step-credentials.component.html',
    styleUrls: ['../base/wizard-step-base.component.scss', 'wizard-step-credentials.component.scss'],
    providers: [{ provide: WizardStepBaseComponent, useExisting: WizardStepCredentialsComponent }],
})
export class WizardStepCredentialsComponent extends WizardStepBaseComponent {
    public id: TWizardStepId = 'credentials';
    public nextButton = 'back';

    public termsUrl: string;

    public constructor(
        private readonly appSettings: AppSettingsService,
        private readonly translate: TranslateService,
        private readonly auth: AuthService,
        private readonly credentials: CredentialsService
    ) {
        super();
    }

    @HostListener('keyup.enter')
    public onSubmit(): void {
        this.wizardService.stepSubmit.emit();
    }

    public validatePasswords(): void {
        if (!this.formControl) return;

        const password = this.formControl.get('password');
        const newPassword = this.formControl.get('newPassword');
        const confirm = this.formControl.get('passwordConfirm');

        if (newPassword.value || confirm.value) {
            newPassword.setErrors(newPassword.value === password.value ? { sameAsOld: true } : null);
            confirm.setErrors(confirm.value && newPassword.value !== confirm.value ? { mismatch: true } : null);
        }

        this.nextButton = this.formControl.dirty ? 'updateCredentials' : 'back';
    }

    public override onBeforeShow(): Observable<{ user: AuthUser; credentials: ICredentials }> {
        return this.auth.user.pipe(
            switchMap(async (user) => {
                const credentials = await this.credentials.getCredentials(user.id);
                return { user, credentials };
            }),
            tap(({ user, credentials }) => {
                this.form.userId = user.id;
                this.form.username = credentials.username;
            })
        );
    }

    protected submitWizardStep(): Observable<boolean> {
        if (!this.formControl.dirty) {
            this.goToPage.emit('logged-in');
            return of(false);
        }

        const { userId, username, newPassword } = this.form;

        return from(this.auth.login(this.form.username, this.form.password)).pipe(
            catchError(() => {
                this.formControl.get('password').setErrors({ invalid: true });
                return of(false);
            }),
            switchMap(async (loginResult) =>
                loginResult ? this.credentials.updateCredentials(userId, username, newPassword) : false
            ),
            catchError((response) => {
                if (response?.error?.errorCode === 'UsernameTaken')
                    this.formControl.get('username').setErrors({ usernameTaken: true });
                if (response?.error?.errorDescription === 'Invalid password specified.')
                    this.formControl.get('newPassword').setErrors({ invalid: true });

                return of(false);
            }),
            map((response) => response !== false)
        );
    }
}
