import { Component, HostListener } from '@angular/core';
import { Observable, of } from 'rxjs';

import { WizardStepBaseComponent } from '../';
import { TWizardStepId } from '../../../interfaces';

@Component({
    selector: 'app-wizard-step-tenant-invite-success',
    templateUrl: './wizard-step-tenant-invite-success.component.html',
    styleUrls: ['../base/wizard-step-base.component.scss', './wizard-step-tenant-invite-success.component.scss'],
    providers: [{ provide: WizardStepBaseComponent, useExisting: WizardStepTenantInviteSuccessComponent }],
})
export class WizardStepTenantInviteSuccessComponent extends WizardStepBaseComponent {
    public id: TWizardStepId = 'tenant-invite-success';
    public nextButton = 'back';

    protected acceptedTenantName: string;

    public override onBeforeShow(): void {
        this.acceptedTenantName = this.form.tenantName;

        // Reset form
        this.form.token = null;
        this.form.tenantName = null;
    }

    @HostListener('keyup.enter')
    public onSubmit(): void {
        this.wizardService.stepSubmit.emit();
    }

    protected submitWizardStep(): Observable<boolean> {
        return of(true);
    }
}
