import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'baseflow-auth';
import { Observable, from, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { WizardStepBaseComponent } from '../';
import { TWizardStepId } from '../../../interfaces';
import { AppSettingsService } from '../../../services/app-settings.service';
import { RoutingService } from '../../../services/routing.service';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-wizard-step-create-account',
    templateUrl: './wizard-step-create-account.component.html',
    styleUrls: ['../base/wizard-step-base.component.scss', 'wizard-step-create-account.component.scss'],
    providers: [{ provide: WizardStepBaseComponent, useExisting: WizardStepCreateAccountComponent }],
})
export class WizardStepCreateAccountComponent extends WizardStepBaseComponent {
    public id: TWizardStepId = 'account';
    public nextButton = 'signUp';
    public termsUrl: string;

    public constructor(
        private readonly appSettings: AppSettingsService,
        private readonly translate: TranslateService,
        private readonly auth: AuthService,
        private readonly routingService: RoutingService,
        private readonly userService: UserService
    ) {
        super();

        appSettings.get(tap((settings) => (this.termsUrl = settings.url.terms))).subscribe();
    }

    @HostListener('keyup.enter')
    public onSubmit(): void {
        this.wizardService.stepSubmit.emit();
    }

    public checkPasswordsMatch(): void {
        if (!this.formControl) return;

        const password = this.formControl.get('password');
        const confirm = this.formControl.get('passwordConfirm');

        confirm.setErrors(confirm.value === password.value ? null : { mismatch: true });
    }

    protected submitWizardStep(): Observable<boolean> {
        const data = this.form;

        return this.auth
            .registerUser({
                username: data.emailAddress,
                password: data.password,
                fullname: data.username,
                language: this.translate.currentLang,
                emailAddress: data.emailAddress,
            })
            .pipe(
                catchError((response) => {
                    if (response?.error?.errorCode === 'UsernameTaken')
                        this.formControl?.get('emailAddress')?.setErrors({ usernameTaken: true });

                    return of(false) as Observable<false>;
                }),
                switchMap((response) => (response === false ? of(false) : this.login()))
            );
    }

    private login(): Observable<boolean> {
        return (this.auth.isLoggedInSync() ? from(this.auth.logout()) : of(null)).pipe(
            take(1),
            switchMap(() => from(this.auth.login(this.form.emailAddress, this.form.password))),
            catchError((error) => this.handleLoginError(error)),
            switchMap((loginResult) =>
                (loginResult === false ? of(null) : this.userService.getProfile()).pipe(
                    map((profile) => ({ loginResult, profile }))
                )
            ),
            map(({ loginResult, profile }) => {
                this.translate.use(profile?.language);
                Object.assign(this.form, profile);

                const route = this.routingService.getRoute();
                if (route.action) {
                    this.goToPage.emit(route.action as TWizardStepId);
                    return false;
                }

                delete this.form.password;

                return loginResult !== false;
            })
        );
    }

    private handleLoginError(err: { code?: string; details: unknown }): Observable<false> {
        console.error(err);
        return of(false);
    }
}
