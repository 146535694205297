<form #stepForm="ngForm" class="form-page" *ngIf="visible">
    <label>
        {{ 'form.language' | translate }}
        <input type="text" name="language" required hidden [(ngModel)]="form.language" />
        <div class="pills">
            <button
                *ngFor="let language of languages"
                (click)="setLanguage(language)"
                class="pill-option"
                [class.active]="language === form.language"
            >
                {{ 'languages.' + language | translate }}
            </button>
        </div>
    </label>
    <label>
        {{ 'form.emailAddress' | translate }}
        <input
            #emailAddress="ngModel"
            type="email"
            name="emailAddress"
            email
            (keyup)="updateNextButton()"
            [placeholder]="'form.emailAddress' | translate"
            [(ngModel)]="form.emailAddress"
        />
    </label>
    <div class="form-error" *ngIf="stepForm.invalid">
        <div *ngIf="emailAddress.errors?.email">
            {{ 'errors.invalidEmail' | translate }}
        </div>
    </div>
</form>
