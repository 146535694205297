import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUserProfile, UserApiService } from 'shared';

@Injectable({ providedIn: 'root' })
export class UserService {
    public constructor(private readonly userApi: UserApiService) {}

    public getProfile(): Observable<IUserProfile> {
        return this.userApi.getProfile();
    }

    public updateProfile(userId: string, language: string, emailAddress: string): Observable<IUserProfile> {
        return this.userApi.putProfile(userId, { language, emailAddress });
    }
}
