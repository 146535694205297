import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'baseflow-auth';
import { Observable, from, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { fade } from 'shared';

import { WizardStepBaseComponent } from '../';
import { TWizardStepId } from '../../../interfaces';
import { AppSettingsService } from '../../../services/app-settings.service';
import { RoutingService } from '../../../services/routing.service';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-wizard-step-login',
    templateUrl: './wizard-step-login.component.html',
    styleUrls: ['../base/wizard-step-base.component.scss', './wizard-step-login.component.scss'],
    providers: [{ provide: WizardStepBaseComponent, useExisting: WizardStepLoginComponent }],
    animations: [fade()],
})
export class WizardStepLoginComponent extends WizardStepBaseComponent {
    public id: TWizardStepId = 'login';
    public nextButton = 'login';

    protected loginError: string | null = null;
    public termsUrl: string;

    public constructor(
        private readonly appSettings: AppSettingsService,
        private readonly translate: TranslateService,
        private readonly auth: AuthService,
        private readonly routingService: RoutingService,
        private readonly userService: UserService
    ) {
        super();

        appSettings.get(tap((settings) => (this.termsUrl = settings.url.terms))).subscribe();
    }

    @HostListener('keyup.enter')
    public onSubmit(): void {
        this.wizardService.stepSubmit.emit();
    }

    protected submitWizardStep(): Observable<boolean> {
        const route = this.routingService.getRoute();

        return (this.auth.isLoggedInSync() ? from(this.auth.logout()) : of(null)).pipe(
            take(1),
            switchMap(() => from(this.auth.login(this.form.emailAddress, this.form.password))),
            catchError((error) => this.handleLoginError(error)),
            switchMap((loginResult) =>
                (loginResult === false ? of(null) : this.userService.getProfile()).pipe(
                    map((profile) => ({ loginResult, profile }))
                )
            ),
            map(({ loginResult, profile }) => {
                if (loginResult === false) return false;

                this.translate.use(profile?.language);
                delete this.form.emailAddress;
                delete this.form.password;
                Object.assign(this.form, profile);
                this.loginError = null;

                if (route.action) {
                    this.goToPage.emit(route.action as TWizardStepId);
                    return false;
                }

                return true;
            })
        );
    }

    private handleLoginError(err: { code?: string; details: unknown }): Observable<false> {
        if (err?.code === 'auth/invalid-credentials') {
            this.loginError = 'errors.invalidCredentials';
        } else {
            console.error(err);
            this.loginError = 'errors.loginUnknownError';
        }
        return of(false);
    }
}
