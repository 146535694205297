import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUserTenantInvitationDetails, UserApiService } from 'shared';

@Injectable({ providedIn: 'root' })
export class InvitationService {
    private constructor(private userApi: UserApiService) {}

    public getInvitation(token: string): Observable<IUserTenantInvitationDetails> {
        return this.userApi.getInvitation(token);
    }

    public acceptInvitation(token: string): Observable<void> {
        return this.userApi.acceptInvitation(token);
    }
}
