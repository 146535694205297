import { DatePipe } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, delay, filter, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { WizardStepBaseComponent } from '../';
import { TWizardStepId } from '../../../interfaces';
import { InvitationService } from '../../../services/invitation.service';
import { RoutingService } from '../../../services/routing.service';

enum State {
    ERROR,
    START,
    LOADED,
    SUCCESS,
}

const InviteErrorCodes = [
    'UserNotFound',
    'UserAlreadyLinkedToTenant',
    'InvitationNotFound',
    'InvitationAlreadyRedeemed',
    'InvitationExpired',
];

interface IToken {
    length: number;
    pattern: string;
}

const TOKEN: Readonly<IToken> = {
    length: 15,
    pattern: '[ABCDEFGHJKMNPQRSTUVWXYZ23456789]*',
};

@Component({
    selector: 'app-wizard-step-tenant-invite',
    templateUrl: './wizard-step-tenant-invite.component.html',
    styleUrls: ['../base/wizard-step-base.component.scss', './wizard-step-tenant-invite.component.scss'],
    providers: [{ provide: WizardStepBaseComponent, useExisting: WizardStepTenantInviteComponent }],
})
export class WizardStepTenantInviteComponent extends WizardStepBaseComponent {
    public id: TWizardStepId = 'tenant-invite';
    public nextButton = 'acceptInvitation';

    protected loadedInvitation: {
        tenantName?: string;
        expirationDate?: string;
    } = {};

    protected readonly stateEnum = State;
    protected readonly inviteErrorCodes = InviteErrorCodes;
    protected readonly tokenValidation = TOKEN;

    protected state: State;

    protected invalidToken: boolean;
    protected maxLengthExceeded: boolean;

    public constructor(
        private readonly routingService: RoutingService,
        private readonly invitationService: InvitationService,
        private readonly datePipe: DatePipe,
        private readonly translate: TranslateService
    ) {
        super();
    }

    @HostListener('keyup.enter')
    public onSubmit(): void {
        if (this.form.token && !this.form.tenantName) {
            this.loadInvitation();
        } else {
            this.wizardService.stepSubmit.emit();
        }
    }

    public override onBeforeShow(): Observable<unknown> {
        this.form.token = this.routingService.getRoute().parameter;
        this.state = State.START;

        return (this.form.token ? this._loadInvitation() : of({})).pipe(delay(0), tap(this.validateForm.bind(this)));
    }

    public override onBeforeValidate(): void {
        this.formControl?.get('tenantName').setErrors(null);
    }

    protected onTokenInput(): void {
        let token = this.form.token;
        if (/^https?/.test(token) && token.includes('tenant-invite')) {
            token = token.slice(token.lastIndexOf('/') + 1);
        }

        this.maxLengthExceeded = token.length > this.tokenValidation.length;
        if (this.maxLengthExceeded) {
            this.state = State.ERROR;
        }

        this.invalidToken = false;

        this.form.token = token.toUpperCase();
        setTimeout(() => {
            if (this.formControl?.get('token').valid) this.loadInvitation();
        });
    }

    protected onTokenPaste(event: ClipboardEvent): void {
        this.form.token = event.clipboardData?.getData('text') || '';
        this.onTokenInput();
    }

    protected loadInvitation(): void {
        this._loadInvitation().subscribe();
    }

    protected validateForm(): void {
        this.formControl?.get('tenantName').setErrors(this.form.tenantName ? null : { required: true });
        this.formControl?.get('token').setErrors(this.form.token ? null : { required: true });
    }

    protected submitWizardStep(): Observable<boolean> {
        return this.invitationService.acceptInvitation(this.form.token).pipe(
            catchError((response) => {
                const errorCode = response?.error?.errorCode;

                this.state = State.ERROR;
                this.formControl?.get('tenantName').setErrors({ [errorCode ?? 'inviteError']: true });

                return of(false);
            }),
            map((success) => {
                if (success === false) return false;

                this.state = State.SUCCESS;

                return true;
            })
        );
    }

    private _loadInvitation(): Observable<unknown> {
        return this.invitationService.getInvitation(this.form.token).pipe(
            catchError(() => {
                this.state = State.ERROR;
                // We need to set this boolean, as the form control is not always available when the invitation is loaded from a token in the URL.
                this.invalidToken = true;
                this.formControl?.get('token').setErrors({ invalidToken: true });
                return of(null);
            }),
            filter(Boolean),
            tap((invitation) => {
                this.form.tenantName = invitation.tenantName;

                this.loadedInvitation = {
                    tenantName: invitation.tenantName,
                    expirationDate: this.datePipe.transform(
                        invitation.expiresAt * 1000,
                        undefined,
                        undefined,
                        `${this.translate.currentLang}-${this.translate.currentLang.toUpperCase()}`
                    ),
                };

                this.state = State.LOADED;

                const expired = invitation.expiresAt * 1000 < Date.now();
                if (expired) {
                    this.state = State.ERROR;
                    this.formControl?.get('token').setErrors({ expiredToken: true });
                }
            })
        );
    }
}
