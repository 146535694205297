import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { ICredentials } from '../../models';
import { AuthRepositoryService } from '../../repositories';

@Injectable({ providedIn: 'root' })
export class CredentialsService {
    public constructor(private authRepository: AuthRepositoryService) {}

    public updateCredentials(userId: string, username: string, password: string): Promise<void> {
        return firstValueFrom(this.authRepository.putCredentials({ userId, username, password }));
    }

    public getCredentials(userId: string): Promise<ICredentials> {
        return firstValueFrom(this.authRepository.getCredentials(userId));
    }
}
