import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

export interface IRoute {
    action: string;
    parameter: string;
}

@Injectable({ providedIn: 'root' })
export class RoutingService {
    public constructor(private readonly router: Router) {}

    public getRoute(): IRoute {
        const [action, parameter] = (
            this.router.createUrlTree([this.router.url], { relativeTo: null }).root.children.primary?.segments ?? []
        ).map((segment) => segment.path);

        return { action, parameter };
    }
}
