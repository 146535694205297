import { EventEmitter, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { IWizardForm, TWizardStepId } from '../interfaces';

@Injectable({ providedIn: 'root' })
export class WizardService {
    public stepSubmit: EventEmitter<void> = new EventEmitter();

    public get goToPage(): EventEmitter<TWizardStepId | number> {
        return this._goToPage;
    }

    public get form(): IWizardForm {
        return this._form;
    }

    private _form: IWizardForm;
    private _goToPage: EventEmitter<TWizardStepId | number>;

    public initialize(
        formData: IWizardForm,
        goToPage: EventEmitter<TWizardStepId | number>,
        onStepSubmit: () => void
    ): void {
        this._form = formData;
        this._goToPage = goToPage;
        this.stepSubmit.pipe(tap(onStepSubmit)).subscribe();
    }
}
